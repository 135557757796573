<template>
  <div class="page__container">
    <main class="page">
      <div class="page__header">
        <div
          class="page__header__sub serif--p1"
          v-html="page.header"
        />
      </div>
      <h1 class="page__body__section__title trim--sans">
        {{ post.title.rendered }}
      </h1>
      <div class="page__body">
        <div
          class="page__body__section__text"
          v-html="page.body"
        />
        <div class="page__body__gaps" />
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import data from '@/mixins/data';
import Footer from '@/components/ui/footer';

export default {
  name: 'Page',
  components: {
    Footer,
  },
  mixins: [data],
  computed: {
    page() {
      return this.post.acf.page;
    },
  },
};
</script>

<style lang="scss">
.page {
  &__container {
    align-content: space-between;
    display: grid;
    height: calc(var(--vh) * 100);
    position: fixed;
    width: 100%;
    z-index: 2;
    overflow-y: auto;
    background: var(--white);
  }

  &__header {
    padding-top: var(--header-height);

    &--about {
      background: var(--blue);
    }

    h1 {
      padding: var(--spacer-l) var(--spacer-xs);
      color: var(--white);
    }

    &__sub {
      padding: var(--spacer-m) var(--spacer-xs);
      background: var(--white);
      margin-bottom: var(--spacer-s);
    }
  }

  &__body {
    background: var(--grey);
    gap: 0;
    position: relative;
    z-index: 0;
    padding-top: var(--spacer-s);
    padding-bottom: var(--spacer-xl);
    border-left: var(--column-gap-xs) var(--grey) solid;

    &--about {
      border-left: initial;
      padding-top: initial;
      padding-bottom: initial;
    }

    @include mq(s) {
      columns: auto 2;
    }

    &__section {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;

      &__title {
        background: var(--grey);
        border-left: var(--column-gap-xs) var(--blue) solid;
        border-top: var(--row-gap-s) var(--blue) solid;
        padding: var(--spacer-xs);
        color: var(--blue);
      }

      &__text {
        background: var(--white);
        padding-left: var(--spacer-xs);
        padding-right: var(--spacer-xs);

        &--about {
          padding-top: var(--spacer-s);
          border-left: var(--column-gap-xs) var(--grey) solid;
          padding-bottom: var(--spacer-xl);
        }
      }

      &:nth-last-child(2) {
        padding-bottom: var(--spacer-xl);
      }
    }

    &__gaps {
      background: var(--white);
      display: grid;
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      @include mq(s) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      &--about {
        &::after,
        &::before {
          border-left: var(--column-gap-xs) var(--grey) solid;
          content: "";
        }
      }
    }
  }
}
</style>
