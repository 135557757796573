<template>
  <main
    id="cycle"
    :class="['cycle', zoomed ? 'cycle--zoomed' : false]"
  >
    <transition name="fade">
      <div
        v-show="centralMessageIsVisible"
        :class="[
          'central-message',
          hoveredPhase ? 'central-message--hovered' : false,
        ]"
      >
        <h2
          v-show="hoveredPhase"
          class="sans--h2"
        >
          {{ hoveredPhase.name }}
        </h2>
        <h4>
          {{ hoveredPhase ? sub : options.central_message }}
        </h4>
      </div>
    </transition>
    <div
      v-if="cases || $route.name === 'News'"
      id="clickable"
    />
    <canvas id="cycle-model" />
    <transition name="fade">
      <Sub
        v-show="showSubs"
        :phase="activePhase"
        @open-cases="openCases"
      />
    </transition>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import { initCycle } from '@/assets/js/cycle';
import Sub from '@/components/cycle/sub-labels';
import data from '@/mixins/data';

export default {
  name: 'Cycle',
  components: { Sub },
  mixins: [data],
  emits: ['open-cases'],
  data() {
    return {
      zoomed: false,
      centralMessageIsVisible: true,
      activePhase: false,
      hoveredPhase: false,
      showSubs: false,
    };
  },
  computed: {
    ...mapGetters(['phases', 'options', 'subPhases', 'cases']),
    sub() {
      let string = '';

      if (this.hoveredPhase) {
        this.hoveredPhase.sub.forEach((phase) => {
          if (string === '') {
            string += phase.name;
          } else {
            string += `, ${phase.name}`;
          }
        });
      }

      return string;
    },
  },
  mounted() {
    initCycle();
    this.$bus.$on('cycleIn', this.cycleIn);
    this.$bus.$on('cycleOut', this.cycleOut);
    this.$bus.$on('labelsReady', this.labelsReady);
  },
  methods: {
    openCases(phase, sub) {
      this.$emit('open-cases', phase, sub);
    },
    cycleIn(element) {
      this.zoomed = true;
      this.centralMessageIsVisible = false;
      const label = element.children[0];
      label.classList.add('cycle__label--active');

      setTimeout(() => {
        this.activePhase = this.phases.find(
          (phase) => phase.slug === element.id,
        );
        if (!label.classList.contains('cycle__label--subbed')) {
          this.openCases(this.activePhase);
        } else {
          this.activePhase.sub = true;
          this.showSubs = true;
          document
            .querySelector('#cycle-labels')
            .addEventListener('click', this.clickedAway);
          label.addEventListener('click', this.handleZoomedClick);
        }
      }, 600);
    },
    handleZoomedClick(event) {
      event.stopPropagation();
      this.openCases(this.activePhase, true);
    },
    cycleOut() {
      this.zoomed = false;
      if (document.querySelector('.cycle__label--active')) {
        document
          .querySelector('.cycle__label--active')
          .classList.remove('cycle__label--active');
      }
      setTimeout(() => {
        this.activePhase = false;
        this.centralMessageIsVisible = true;
        document
          .querySelector('#cycle-labels')
          .removeEventListener('click', this.clickedAway);
      }, 600);
    },
    clickedAway() {
      if (this.activePhase.sub) {
        this.showSubs = false;
        this.activePhase.sub = false;
        setTimeout(() => {
          this.$bus.$emit('zoomOut');
        }, 200);
      }
    },
    labelsReady() {
      const labels = [...document.querySelector('#cycle-labels').children];
      labels.forEach((label) => {
        label.addEventListener('mouseenter', (e) => {
          this.$bus.$emit('pauseAnimation');
          this.hoveredPhase = this.phases.find(
            (phase) => phase.slug === e.target.id,
          );
          this.hoveredPhase.sub = this.subPhases(this.hoveredPhase.id);
        });
        label.addEventListener('mouseleave', () => {
          this.$bus.$emit('resumeAnimation');
          this.hoveredPhase = false;
        });
      });
    },
  },
};
</script>

<style lang="scss">
  .cycle {
    --dot-height: 20px;

    align-items: center;
    gap: var(--column-gap-m);
    display: none;
    height: calc(var(--vh) * 100);
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    place-items: center;
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 100%;
    // cursor: grab;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    > * {
      grid-area: 1 / -1;
    }

    &__label {
      &__container {
        cursor: pointer;
        will-change: transform;

        @media (hover: hover) {
          &:hover {
            color: var(--blue);
            --black: var(--blue);
          }
        }
      }

      display: grid;
      justify-items: center;
      row-gap: var(--row-gap-xs);
      padding-bottom: calc(var(--dot-height) + var(--row-gap-xs));
      transition: transform var(--zoom-timing) ease;
      pointer-events: none;

      h4 {
        text-shadow: 0 calc(var(--line) * -1) 0 var(--white),
          0 calc(var(--line) * 1) 0 var(--white),
          calc(var(--line) * 1) 0 0 var(--white),
          calc(var(--line) * 1) calc(var(--line) * 1) 0 var(--white),
          calc(var(--line) * -1) calc(var(--line) * 1) 0 var(--white),
          calc(var(--line) * 1) calc(var(--line) * -1) 0 var(--white),
          calc(var(--line) * -1) calc(var(--line) * -1) 0 var(--white),
          calc(var(--line) * -1) 0 0 var(--white);
      }

      &__dot {
        height: var(--dot-height);
        width: var(--dot-height);
        background: var(--black);
        border-radius: calc(var(--dot-height) / 2);
        border: var(--white) solid calc(var(--line) * 2);
        transition: transform var(--zoom-timing) ease;
      }

      &--subbed {
        .cycle__label__dot {
          background: var(--white);
          border: var(--black) solid calc(var(--line) * 2);
          display: grid;
          place-items: center;

          > * {
            grid-area: 1 / -1;
          }

          &::after {
            content: "";
            background: var(--black);
            display: grid;
            height: calc(var(--dot-height) / 2);
            width: calc(var(--dot-height) / 2);
            border-radius: calc(var(--dot-height) / 4);
          }
        }
      }
    }

    #commenting-contributing,
    #updating-adding-emending {
      .cycle__label__dot {
        background: var(--white);
        border: var(--black) solid calc(var(--line) * 2);
        display: grid;
        place-items: center;

        > * {
          grid-area: 1 / -1;
        }

        &::after {
          content: "";
          background: var(--black);
          display: grid;
          height: calc(var(--dot-height) / 2);
          width: calc(var(--dot-height) / 2);
          border-radius: calc(var(--dot-height) / 4);
        }
      }
    }

    // #evaluating {
    //   --dot-height: 15px;

    //   pointer-events: none;

    //   .cycle__label__dot {
    //     background: var(--white);
    //     border: var(--black) solid calc(var(--line) * 2);
    //   }
    // }

    &--zoomed {
      cursor: url("~@/assets/svg/icons/close.svg"), auto;

      .cycle__label {
        &__container {
          pointer-events: none;
        }
        --black: var(--grey);

        @media (hover: hover) {
          &:hover {
            color: var(--blue);
            --black: var(--blue);
          }
        }

        &--sub {
          --black: rgb(0, 0, 0);
          pointer-events: initial;
          color: var(--black);
        }

        transform: scale(2);
        color: var(--grey);
        cursor: pointer;

        &__dot {
          transform: scale(1.2);
        }

        &--active {
          --black: var(--blue);

          color: var(--blue);
          pointer-events: initial;
        }
      }
    }
  }

  #cycle-labels {
    position: absolute;
    z-index: 0;
  }

  .central-message {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 256px;
    pointer-events: none;
    row-gap: var(--row-gap-xs);
    text-align: center;

    &--hovered {
      color: var(--blue);
      z-index: 100;
    }
  }

  #clickable {
    position: fixed;
    z-index: 1;
    // background: var(--blue);
    width: 100%;
    height: calc(100 * var(--vh));
  }
</style>
