<template>
  <div
    ref="container"
    class="case__container"
    tabindex="-1"
    @keydown.esc="close"
  >
    <div
      ref="case"
      class="case"
    >
      <Cta
        class="close"
        theme="nav--large"
        :data="{
          fn: close,
          title: $labels.close_cta,
        }"
      />
      <div class="case__header">
        <h1 class="serif--h1 trim--serif--display">
          {{ post.title.rendered }}
        </h1>
        <Gallery :data="post.acf.gallery" />
      </div>
      <div class="case__details">
        <div
          v-for="(field, index) in orderedFields"
          v-show="field.values"
          :key="index"
          class="case__details__detail"
        >
          <div
            :class="[
              'case__details__detail__label',
              field.explanation
                ? 'case__details__detail__label--hoverable'
                : false,
            ]"
          >
            <div>
              <h5
                v-touch:tap="
                  () => {
                    field.explanation ? toggleExplanation() : false;
                  }
                "
                class="sans--h5--alt trim--sans"
                @mouseenter="field.explanation ? toggleExplanation() : false"
                @mouseleave="field.explanation ? toggleExplanation() : false"
              >
                {{ field.label }}
                <sup v-if="field.explanation">?</sup>
              </h5>
              <div
                v-if="field.explanation"
                v-show="explanationIsVisible"
                class="case__details__detail__explanation"
              >
                <div class="case__details__detail__explanation__triangle" />
                <h5 class="trim--sans">
                  {{ field.explanation }}
                </h5>
              </div>
            </div>
          </div>
          <h4
            v-if="field.label !== 'Url'"
            class="trim--sans"
          >
            <span
              v-for="(value, valueIndex) in field.values"
              :key="value.term_taxonomy_id"
            >
              {{
                `${value.name}${
                  valueIndex + 1 !== field.values.length ? ", " : ""
                }`
              }}
            </span>
          </h4>
          <Cta
            v-else-if="field.values"
            theme="small"
            :data="{
              url: field.values.url,
              target: '__blank',
              title: field.values.title,
            }"
          />
        </div>
        <div class="case__details__detail end" />
      </div>
      <div
        class="case__text"
        v-html="post.acf.text"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import data from '@/mixins/data';
import Cta from '@/components/typo/cta';
import Gallery from '@/components/media/gallery';

export default {
  name: 'Case',
  components: {
    Cta,
    Gallery,
  },
  mixins: [data],
  data() {
    return {
      explanationIsVisible: false,
    };
  },
  computed: {
    ...mapGetters(['options']),
    orderedFields() {
      const taxonomies = this.post.gds_taxonomies;
      const { url } = this.post.acf.fields;
      const fields = [];

      fields.push({
        label: this.$labels.year,
        values: taxonomies.case_study_year,
      });
      fields.push({
        label: this.$labels.category,
        values: taxonomies.case_study_category,
        explanation: this.options.category_explanation,
      });
      fields.push({
        label: this.$labels.type,
        values: taxonomies.case_study_type,
      });
      fields.push({
        label: this.$labels.media,
        values: taxonomies.case_study_media,
      });
      fields.push({
        label: this.$labels.scope,
        values: taxonomies.case_study_scope,
      });
      fields.push({
        label: this.$labels.identifier,
        values: taxonomies.case_study_identifier,
      });
      fields.push({
        label: this.$labels.url,
        values: url,
      });

      return fields;
    },
  },
  mounted() {
    this.$bus.$emit('removeCasesListener');
    window.addEventListener('pointerdown', this.handleClick);
    this.$refs.container.focus();
  },
  beforeDestroy() {
    window.removeEventListener('pointerdown', this.handleClick);
  },
  methods: {
    toggleExplanation() {
      this.explanationIsVisible = !this.explanationIsVisible;
    },
    handleClick(event) {
      event.stopPropagation();
      if (!this.$refs.case.contains(event.target)) {
        this.close();
      }
    },
    close() {
      this.$bus.$emit('addCasesListener');
      if (this.$store.state.route.from.name) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'Home' });
      }
    },
  },
};
</script>

<style lang="scss">
.case {
  &__container {
    align-content: end;
    display: grid;
    height: calc(var(--vh) * 100);
    position: fixed;
    width: 100%;
    z-index: 4;
    cursor: url("~@/assets/svg/icons/close.svg"), auto;

    @include mq(s) {
      place-content: center;
      height: calc(var(--vh) * 100);
    }
  }

  --case-width: 1000px;

  align-content: start;
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--line) var(--line) 0px calc(var(--line) * 2) var(--grey-d);
  display: grid;
  height: calc(var(--vh) * 100 - var(--spacer-header));
  max-width: var(--case-width);
  overflow: auto;
  overscroll-behavior: contain;
  padding: var(--spacer-xs);
  cursor: auto;
  position: relative;
  row-gap: var(--row-gap-m);

  @include mq(s) {
    height: calc(var(--vh) * 100 - var(--spacer-header) * 2);
  }

  &__header {
    display: grid;
    row-gap: var(--row-gap-l);

    h1 {
      margin-top: var(--spacer-s);
      text-align: center;

      @include mq(s) {
        margin-top: var(--spacer-l);
      }
    }
  }

  &__details {
    &__detail {
      border-top: var(--line) solid var(--grey-d);
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      gap: var(--column-gap-s);
      padding-bottom: var(--spacer-m);
      padding-top: var(--spacer-xs);
      position: relative;

      &.end {
        padding: 0;
      }

      &__label {
        > * {
          column-gap: var(--column-gap-xs);
          display: grid;
          grid-auto-flow: column;
        }
        &--hoverable {
          cursor: default;

          > * {
            position: absolute;
          }
        }
      }

      &__explanation {
        display: grid;
        grid-auto-flow: column;
        z-index: 1;

        &__triangle,
        &__triangle::after {
          --triangle-size: 16px;

          border-top: calc(var(--triangle-size) * 0) solid transparent;
          border-bottom: calc(var(--triangle-size) * 0.4) solid transparent;
          border-right: var(--triangle-size) solid var(--grey-d);
          height: 0;
          width: 0;
          position: relative;
        }

        &__triangle::after {
          left: 4px;
          top: 1px;
          position: absolute;
          content: "";
          border-right-color: var(--white);
        }

        h5 {
          background: var(--white);
          color: var(--blue);
          border: var(--line) solid var(--grey-d);
          max-width: 100px;
          padding: var(--spacer-xxs);

          @include mq(s) {
            max-width: 300px;
          }
        }
      }
    }
  }

  &__text {
    margin-bottom: var(--spacer-xl);
  }

  .close {
    position: absolute;
    z-index: 1;
    bottom: var(--spacer-xs);
    margin: var(--spacer-xs);
    text-align: center;

    > * {
      width: 100%;
      position: fixed;
      left: 0;

      @include mq(s) {
        left: initial;
        width: initial;
      }
    }

    @include mq(s) {
      bottom: initial;
      width: initial;
    }
  }
}
</style>
