<template>
  <div class="cycle-sub-labels">
    <div
      v-for="(phase, index) in phases"
      :key="phase.id"
      class="cycle__label__container"
      :style="
        `transform: rotate(${(360 / phases.length) *
          index}deg) translateY(-200px) rotate(${(-360 / phases.length) *
          index}deg)`
      "
    >
      <div
        class="cycle__label cycle__label--sub"
        @click.stop="openCases(phase, true)"
      >
        <h4>{{ phase.name }}</h4>
        <div class="cycle__label__dot" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CycleSubLabels',
  props: {
    phase: {
      type: [Boolean, Object],
      default: false,
    },
  },
  emits: ['open-cases'],
  computed: {
    ...mapGetters(['subPhases']),
    phases() {
      return this.subPhases(this.phase.id);
    },
  },
  methods: {
    openCases(phase, sub) {
      this.$emit('open-cases', phase, sub);
    },
  },
};
</script>

<style lang="scss">
  .cycle-sub-labels {
    --sub-size: 400px;

    display: grid;
    height: var(--sub-size);
    place-items: center;
    pointer-events: none;
    width: var(--sub-size);
    z-index: 1;

    > * {
      grid-area: 1 / -1;
    }
  }
</style>
