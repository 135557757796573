var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"cases__container",attrs:{"tabindex":"-1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"cases"},[_c('div',{staticClass:"cases__header"},[_c('Cta',{staticClass:"cases__header__cta",attrs:{"theme":"nav--inverse","data":{
          fn: _vm.close,
          title: _vm.$labels.close_cta,
        }}}),_c('Cta',{directives:[{name:"visible",rawName:"v-visible",value:(false),expression:"false"}],attrs:{"theme":"nav--inverse","data":{
          fn: _vm.close,
          title: _vm.$labels.close_cta,
        }}}),_c('h1',{staticClass:"sans--h1--shadow trim--sans"},[_vm._v(" "+_vm._s(_vm.phase.name)+" ")]),_c('Cta',{directives:[{name:"visible",rawName:"v-visible",value:(false),expression:"false"}],attrs:{"theme":"nav--inverse","data":{
          fn: _vm.close,
          title: _vm.$labels.close_cta,
        }}})],1),_c('div',{staticClass:"cases__header__body"},[(_vm.phase.description && _vm.phase.description !== '')?_c('div',{staticClass:"cases__header__description trim--sans sans--h4",domProps:{"innerHTML":_vm._s(_vm.phase.description)}}):_vm._e(),_c('Archive',{directives:[{name:"show",rawName:"v-show",value:(_vm.phase),expression:"phase"}],attrs:{"active-phase":_vm.phase.id,"data":{
          archive_type: 'archive',
          layout: '',
          archive: 'case_study',
        }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }