<template>
  <div
    :class="orderable ? 'triangled' : false"
    @click="orderable ? orderItems() : false"
  >
    <h5 class="trim--sans">
      {{ label }}
    </h5>
    <div
      v-if="active"
      :class="['triangle', !direction ? 'triangle--reverse' : false]"
    />
  </div>
</template>

<script>
export default {
  name: 'ArchiveOrder',
  props: {
    label: {
      type: String,
      default: '',
    },
    order: {
      type: String,
      default: '',
    },
    orderable: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['order-items'],
  methods: {
    orderItems() {
      this.$emit('order-items', this.order);
    },
  },
};
</script>

<style lang="scss">

</style>
