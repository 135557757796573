<template>
  <nav class="menu">
    <div class="home">
      <Cta
        :theme="theme === 'blue' ? 'nav--blue' : 'nav'"
        :data="
          listView && $route.name === 'Cases' && !$mq.isMobileS
            ? { fn: () => TOGGLE_VIEW(false), title: headerMenu[0].content }
            : {
              url: listView ? { name: 'Cases' } : { name: 'Home' },
              title: headerMenu[0].content,
            }
        "
      />
    </div>
    <div
      v-if="!$mq.isMobileS"
      class="pages"
    >
      <Cta
        v-for="item in headerMenu.slice(1)"
        :key="item.url"
        :theme="theme === 'blue' ? 'nav--blue' : 'nav'"
        :data="{
          url: item.url,
          title: item.content,
        }"
      />
    </div>
    <Cta
      v-else
      :theme="theme === 'blue' ? 'nav--blue' : 'nav'"
      :data="{
        fn: () => TOGGLE_MENU(!mobileMenu),
        title: mobileMenu ? $labels.close_cta : $labels.menu_cta,
      }"
    />
    <div
      v-show="mobileMenu"
      class="mobile-menu"
    >
      <Cta
        v-for="item in headerMenu.slice(1)"
        :key="item.url"
        theme="menu"
        :data="{
          url: item.url,
          title: item.content,
        }"
      />
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  name: 'Menu',
  components: {
    Cta,
  },
  props: {
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['menu', 'listView']),
    ...mapState(['mobileMenu']),
    headerMenu() {
      const nav = this.menu('Header');
      return nav ? nav.items : null;
    },
  },
  methods: {
    ...mapMutations(['TOGGLE_VIEW', 'TOGGLE_MENU']),
  },
};
</script>

<style lang="scss">
.menu {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;

  .pages {
    display: grid;
    grid-auto-flow: column;
    gap: var(--column-gap-s);
  }

  .home {
    .router-link-exact-active {
      color: inherit;
    }
  }

  .mobile-menu {
    align-content: center;
    background: var(--grey);
    display: grid;
    gap: var(--row-gap-xs);
    height: calc(var(--vh) * 100);
    left: 0;
    padding: var(--spacer-xs);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}
</style>
