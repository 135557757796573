<template>
  <div class="page__container">
    <main>
      <h1 class="page__body__section__title trim--sans">
        <span class="sans--h3">{{ date }}</span>
        {{ post.title.rendered }}
      </h1>
      <div class="content">
        <Richtext
          :data="{ value: post.content.rendered }"
          theme="news"
        />
        <Cta
          theme="small"
          :data="{ title: $labels.news_back, url: { name: 'News' } }"
        />
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import data from '@/mixins/data';
import Footer from '@/components/ui/footer';
import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'SingleNews',
  components: { Footer, Richtext, Cta },
  mixins: [data],
  computed: {
    date() {
      const date = new Date(this.post.date);
      return date.toLocaleDateString('it').replaceAll('/', '.');
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  padding-top: var(--header-height);

  .content {
    display: grid;
    justify-items: start;
    gap: var(--spacer-m);
    padding: var(--spacer-s) var(--spacer-xs) var(--spacer-l) var(--spacer-xs);
    border-left: var(--column-gap-xs) var(--grey) solid;
  }
}
</style>
