<template>
  <Spacer>
    <div
      v-if="theme === 'list'"
      class="archive--list__header thumb--list"
    >
      <Order
        v-for="header in listHeaders"
        :key="header.order"
        :label="header.label"
        :order="header.order"
        :active="activeOrder === header.order"
        :direction="orderDirection"
        :orderable="header.orderable"
        @order-items="orderItems"
      />
    </div>
    <div
      v-if="items.length > 0"
      :class="['archive', theme !== '' ? `archive--${theme}` : false]"
    >
      <component
        :is="
          data.thumb && data.thumb !== '' ? thumb(data.thumb) : thumb(item.type)
        "
        v-for="(item, index) in activePhase ? filteredItems : orderedItems"
        :key="index"
        :data="item"
      />
    </div>
    <div v-else>
      <Skeleton
        :items="skeletonItems"
        :thumb-component="type"
      />
    </div>

    <div
      v-show="items && data.infinite_scroll"
      ref="infinite"
      class="infinite"
    />

    <Cta
      v-if="data.cta"
      :data="data.cta"
    />
  </Spacer>
</template>

<script>
import archive from '@/mixins/archive';

import Thumb from '@/components/thumb';
import ListThumb from '@/components/thumb/list';
import NewsThumb from '@/components/thumb/news';
import Cta from '@/components/typo/cta';
import Skeleton from '@/components/ui/skeleton';
import Order from '@/components/blocks/archive-order';

const thumbs = new Map();
thumbs.set('default', Thumb);
thumbs.set('list', ListThumb);
thumbs.set('news', NewsThumb);

export default {
  name: 'Archive',
  components: {
    Cta,
    Skeleton,
    Order,
  },
  mixins: [archive],
  props: {
    data: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: '',
    },
    activePhase: {
      type: [Number, Boolean],
      default: false,
    },
  },
  data() {
    return {
      filteredItems: [],
      reverseItems: false,
      activeOrder: 'id',
      orderDirection: true,
      newsListener: false,
      listHeaders: [
        {
          order: 'id',
          label: this.$labels.id,
          orderable: true,
        },
        {
          order: 'title',
          label: this.$labels.title,
          orderable: true,
        },
        {
          order: 'case_study_phases',
          label: this.$labels.phases,
          orderable: false,
        },
        {
          order: 'case_study_category',
          label: this.$labels.category,
          orderable: true,
        },
        {
          order: 'case_study_year',
          label: this.$labels.year,
          orderable: true,
        },
      ],
    };
  },
  computed: {
    orderedItems() {
      if (this.reverseItems) {
        return [...this.items].reverse();
      }
      return this.items;
    },
  },
  watch: {
    activePhase(phase) {
      if (!phase.isNaN) {
        this.filteredItems = [...this.items].filter((item) => item.case_study_phase.includes(phase));
      }
    },
    items(value) {
      if (value && this.activePhase && !this.activePhase.isNaN) {
        this.filteredItems = [...this.items].filter((item) => item.case_study_phase.includes(this.activePhase));
      }
    },
  },
  methods: {
    thumb(key) {
      return key && thumbs.get(key) ? thumbs.get(key) : thumbs.get('default');
    },
    orderItems(order) {
      let { items } = this;
      const firstOrder = this.activeOrder !== order;

      if (firstOrder) {
        this.activeOrder = order;
        this.orderDirection = true;
      } else {
        this.orderDirection = !this.orderDirection;
      }

      if (this.filteredItems.length > 0) {
        items = this.filteredItems;
      }

      if (order === 'id') {
        if (this.orderDirection) {
          items.sort((a, b) => a.acf.fields.id - b.acf.fields.id);
        } else items.sort((a, b) => b.acf.fields.id - a.acf.fields.id);
      } else if (order === 'title') {
        if (this.orderDirection) {
          items.sort((a, b) => a.title.rendered.localeCompare(b.title.rendered));
        } else {
          items.sort((a, b) => b.title.rendered.localeCompare(a.title.rendered));
        }
      } else if (this.orderDirection) {
        items.sort((a, b) => a.gds_taxonomies[order][0].name.localeCompare(
          b.gds_taxonomies[order][0].name,
        ));
      } else {
        items.sort((a, b) => b.gds_taxonomies[order][0].name.localeCompare(
          a.gds_taxonomies[order][0].name,
        ));
      }
    },
  },
};
</script>

<style lang="scss">
.infinite {
  visibility: hidden;
}

.archive {
  display: grid;
  row-gap: var(--row-gap-xs);

  &--news {
    align-items: start;
    column-gap: var(--column-gap-s);
    row-gap: var(--row-gap-archive);

    @include mq(s) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &--list {
    row-gap: 0;

    &__header {
      @extend %sans--h5--alt;
      padding: 0;

      h5 {
        overflow: visible;
      }

      @include mq(s) {
        padding: var(--spacer-xs) 0;
      }

      .triangled {
        display: none;

        @include mq(s) {
          display: grid;
        }
      }

      > * {
        display: none;

        @include mq(s) {
          display: initial;
        }
      }
    }
  }
}
</style>
