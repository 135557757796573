import { mapGetters } from 'vuex';
// eslint-disable-next-line no-undef
const { i18n } = __VUE_WORDPRESS__.state;

export default {
  props: {
    preview: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      post: null,
      footer: null,
    };
  },
  created() {
    this.setData(this.$route);
  },
  computed: {
    ...mapGetters(['singleBySlug']),
  },
  methods: {
    setData(to) {
      if (this.preview) {
        this.post = this.preview;
        return;
      }

      if (to.name !== 'About') {
        this.footer = this.singleBySlug({
          type: 'pages',
          slug: 'about',
          lang: 'default',
        }).acf.about.footer;
      }

      const request = {
        type: null,
        slug: null,
        lang: 'en',
      };
      request.type = to.meta.type || 'pages';

      if (to.params.slug) {
        request.slug = to.params.slug;
      } else if (to.meta.slug) {
        request.slug = to.meta.slug;
      }

      request.lang = to.params.lang
        ? to.params.lang
        : i18n
          ? i18n.default
          : 'default';

      this.post = this.$store.state.wp[request.type][request.lang][request.slug];
      this.$store.commit('SET_CURRENT_POST', this.post);
    },
  },
};
