<template>
  <div
    :class="[
      'cta',
      theme !== '' ? `cta--${theme}` : false,
      data.size ? `typo--${data.size}` : null,
      disabled ? 'cta-disabled' : null,
    ]"
  >
    <template v-if="data.fn">
      <button @click.stop="!disabled ? data.fn() : null">
        <div
          :class="['trim--sans']"
          v-html="data.title"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="modal">
      <button @click.stop="openModal">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="hash">
      <button @click.stop="goTo">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="external">
      <a
        :href="data.url"
        :target="data.target"
        @click.stop
      >
        <div
          :class="['trim--sans']"
          v-html="data.title"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else>
      <router-link
        :to="url"
        @click.native.stop
      >
        <div
          :class="['trim--sans']"
          v-html="data.title"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Cta',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url() {
      if (
        this.data.url
        && typeof this.data.url === 'string'
        && process.env.VUE_APP_BASE_URL
        && process.env.VUE_APP_BASE_URL !== '/'
      ) {
        return this.data.url.replace(process.env.VUE_APP_BASE_URL, '/');
      }
      return this.data.url;
    },
    external() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('http')
      );
    },
    hash() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('#')
      );
    },
    modal() {
      return (
        this.url
        && typeof this.url === 'string'
        && this.url.startsWith('#modal-')
      );
    },
  },
  methods: {
    openModal() {
      if (!this.disabled) {
        const id = this.data.url.replace('#modal-', '');
        this.$bus.$emit('openModal', id);
      }
    },
    goTo() {
      if (!this.disabled) {
        const hash = this.data.url;
        gsap.killTweensOf(window);
        gsap.to(window, {
          scrollTo: {
            y: document.querySelector(hash),
            offsetY: 0, // header height
            autoKill: true,
          },
          duration: 1,
          ease: 'expo.out',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.cta {
  a,
  button {
    display: grid;
  }

  &--button {
    a,
    button {
      // display: block;
      border-radius: var(--border-radius);
      background: var(--black);
      color: var(--white);
      padding: var(--spacer-s) var(--spacer-m);
      text-align: center;
    }
  }

  &--nav {
    @extend %sans--h3--nav;

    @media (hover: hover) {
      &:hover {
        color: var(--blue);
      }
    }

    &--large {
      @extend %sans--h3--nav--large;

      @media (hover: hover) {
        &:hover {
          color: var(--blue);
        }
      }
    }

    &--blue {
      @extend %sans--h3--nav;

      .router-link-exact-active {
        color: var(--white);
      }

      @media (hover: hover) {
        &:hover {
          color: var(--white);
        }
      }
    }

    &--inverse {
      @extend %sans--h3--nav;

      @media (hover: hover) {
        &:hover {
          color: var(--blue);
        }
      }

      @include mq(s) {
        @media (hover: hover) {
          &:hover {
            color: var(--white);
          }
        }
      }
    }
  }

  &--menu {
    a,
    button {
      @extend %sans--h2;
      color: var(--black);
      padding: var(--spacer-m);
      border-radius: var(--border-radius);
      box-shadow: var(--line) var(--line) 0px calc(var(--line) * 2)
        var(--shadow);
      text-align: center;

      @media (hover: hover) {
        &:hover {
          box-shadow: 0px 0px 0px var(--line) var(--shadow);
          transform: translate(var(--line), var(--line));
        }
      }
    }
  }

  &--cycle {
    @extend %sans--h4;

    @media (hover: hover) {
      &:hover {
        color: var(--blue);
      }
    }
  }

  &--filter {
    @extend %sans--h3;
    background-color: var(--white);
    padding: var(--spacer-xxs);

    @media (hover: hover) {
      &:hover {
        background-color: var(--blue);
        color: var(--white);
      }
    }

    &--active {
      @extend %sans--h3;
      background-color: var(--blue);
      color: var(--white);
      padding: var(--spacer-xxs);
    }
  }

  &--small {
    @extend %sans--h4;
    text-decoration: underline;
  }

  // &--warning {
  //   color: var(--warning-color);
  // }
  &--login {
    a,
    button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row-reverse;
      width: 100%;
      column-gap: var(--spacer-m);
      border-radius: var(--border-radius);
      border: var(--line) solid var(--grey-d);
      padding: var(--spacer-s) var(--spacer-m);
      line-height: normal;

      @include mq($and: $hover) {
        &:hover {
          background: var(--white);
        }
      }
    }
  }
}
</style>
