/* eslint-disable */

import Vue from "vue";
import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { CSS2DRenderer, CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min";
import model3d from "../cycle/cycle.glb";

export function initCycle() {
  let orthoCam,
  scene,
  controls,
  renderer,
  labelRenderer,
  lineMaterial,
  lineDashedMaterial;

  const orbit = [];
  const orbitDashed = [];

  const camCtrl = {
    crntRot: {
      x: 1.5,
      y: 0,
    },
    trgtRot: {
      x: 1.5,
      y: 0,
    },
  }

  const points = [];

  const maxZoom = 6;

  let bAutorotate = true;

  let pauseAnimation = false;
  let isZoomed = false;

  init();
  animate();

  function init() {
    orthoCam = new THREE.OrthographicCamera(
      window.innerWidth / -2,
      window.innerWidth / 2,
      window.innerHeight / 2,
      window.innerHeight / -2,
      30,
      70
    );
    orthoCam.position.z = 50;
    orthoCam.zoom = getZoom();

    orthoCam.updateProjectionMatrix();

    scene = new THREE.Scene();
    scene.background = null;

    const loader = new GLTFLoader();

    loader.load(model3d, (glb) => {
      const modelCircle = glb.scene.children[0];
      const modelDashed = glb.scene.children[1];
      const modelPoints = glb.scene.children[2];

      modelPoints.children.forEach((child, idx) => {
        points.push(child.clone());
        scene.add(points[idx]);
      });

      points.sort((a, b) => a.name.localeCompare(b.name));

      // Materials
      lineMaterial = new LineMaterial({
        color: 0xffffff,
        vertexColors: true,
        linewidth: 2,
        alphaToCoverage: true,
        dashed: false,
      });

      lineDashedMaterial = new LineMaterial({
        color: 0x000000,
        linewidth: 2,
        alphaToCoverage: true,
        dashed: true,
        dashSize: 5,
        gapSize: 6,
        dashScale: 300,
      });

      lineMaterial.resolution.set(window.innerWidth, window.innerHeight);
      lineDashedMaterial.resolution.set(window.innerWidth, window.innerHeight);

      const orbitGeo = [];
      modelCircle.children.forEach((child, idx) => {
        orbitGeo.push(new LineGeometry());
        const colors = [];
        let numVertices = child.geometry.attributes.position.count;
        if (idx === 0) numVertices = numVertices*0.5+1; // da correggere: il modello del cerchio fa 2 giri
        const bluePart = 0.2;
        for (let id = 0; id < numVertices; id++) {
          const normPos = id/numVertices;
          if (idx === 0 && normPos < bluePart) {
            const val = Math.sin(Math.PI*normPos/bluePart);
            colors.push(0.2*val, 0.5*val, val);
          }
          else colors.push(0, 0, 0);
        }
        let posVertices = child.geometry.attributes.position.array;
        if (idx === 0) posVertices = posVertices.filter((el, id) => id < numVertices*3); // da correggere: il modello del cerchio fa 2 giri
        orbitGeo[idx].setPositions( posVertices );
        orbitGeo[idx].setColors( colors );
        orbit.push(new Line2(orbitGeo[idx], lineMaterial));
        orbit[idx].computeLineDistances();
        orbit[idx].scale.set( 1, 1, 1 );
        scene.add(orbit[idx]);
      });

      const orbitDashedGeo = [];
      modelDashed.children.forEach((child, idx) => {
        orbitDashedGeo.push(new LineGeometry());
        orbitDashedGeo[idx].setPositions(child.geometry.attributes.position.array);
        orbitDashed.push(new Line2(orbitDashedGeo[idx], lineDashedMaterial));
        orbitDashed[idx].computeLineDistances();
        orbitDashed[idx].scale.set( 1, 1, 1 );
        scene.add(orbitDashed[idx]);
      });

      // Labels

      const labels = [];

      points.forEach((point, idx) => {
        const labelContainer = document.createElement("div");
        const label = document.createElement("div");
        const text = document.createElement("h4");
        const dot = document.createElement("div");
        dot.classList.add("cycle__label__dot");
        labelContainer.appendChild(label);
        label.appendChild(text);
        label.appendChild(dot);
        labelContainer.classList.add("cycle__label__container");
        label.classList.add("cycle__label");
        labelContainer.addEventListener("pointerdown", zoomIn);
        labelContainer.index = idx + 1;
        labels.push(labelContainer);

        const cssObject = new CSS2DObject(labelContainer);
        cssObject.position.set(0, 0, 0);
        point.add(cssObject);
      });

      const phases = Object.values(
        window.__VUE_WORDPRESS__.state.taxonomies.phases.default
      );

      function setLabel(index, slug) {
        const label = labels[index];

        if (slug === "placeholder") {
          label.children[0].children[0].textContent = "Evaluating";
          label.id = slug;
          return;
        }

        const phase = phases.find((phase) => phase.slug === slug);

        label.children[0].children[0].textContent = phase.name;
        label.id = slug;

        if (
          phases.filter((element) => element.parent === phase.id).length > 0
        ) {
          label.children[0].classList.add("cycle__label--subbed");
        }
      }

      setLabel(0, "exploring");
      setLabel(1, "sharing");
      setLabel(2, "connecting");
      setLabel(3, "producing");
      setLabel(4, "evaluating");
      setLabel(5, "publishing");
      setLabel(6, "commenting-contributing");
      setLabel(7, "evaluating");
      setLabel(8, "updating-adding-emending");
      setLabel(9, "evaluating");
      setLabel(10, "assessment");
      setLabel(11, "reading");
      setLabel(12, "re-using");
    });

    const webglCanvas = document.querySelector("#cycle-model");

    renderer = new THREE.WebGLRenderer({
      canvas: webglCanvas,
      antialias: true,
      alpha: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor( 0x000000, 0.0 );
    renderer.setSize(window.innerWidth, window.innerHeight);

    labelRenderer = new CSS2DRenderer();
    labelRenderer.setSize(window.innerWidth, window.innerHeight);
    labelRenderer.domElement.index = 0;
    labelRenderer.domElement.id = "cycle-labels";

    const cycle = document.querySelector("#cycle");
    cycle.append(labelRenderer.domElement);

    function observerCallback(mutations) {
      if (mutations.length === labelRenderer.domElement.children.length) {
        Vue.prototype.$bus.$emit("labelsReady");
      }
    }

    const observer = new MutationObserver(observerCallback);

    const observerOptions = {
      childList: true,
      subtree: true,
    };

    observer.observe(document.querySelector("#cycle"), observerOptions);

    window.addEventListener("resize", onWindowResize);

    createControls(orthoCam);
  }

  // Functions

  function createControls(camera) {
    controls = new TrackballControls(camera, labelRenderer.domElement);

    controls.noPan = true;
    controls.noZoom = true;
    controls.rotateSpeed = 3;
    controls.enabled = false;
  }

  function onWindowResize() {
    orthoCam.left = window.innerWidth / -2;
    orthoCam.right = window.innerWidth / 2;
    orthoCam.top = window.innerHeight / 2;
    orthoCam.bottom = window.innerHeight / -2;
    const zoom = isZoomed ? maxZoom : 1;
    orthoCam.zoom = zoom * getZoom();
    orthoCam.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
    labelRenderer.setSize(window.innerWidth, window.innerHeight);

    controls.handleResize();
  }

  controls.addEventListener("start", () => {
    bAutorotate = false;
  });
  controls.addEventListener("end", () => {
    bAutorotate = true;
  });

  document.querySelector("#cycle").addEventListener("mousemove", (e) => {
    if (!pauseAnimation && !isZoomed) {
      camCtrl.trgtRot.x = 0.5*Math.PI*(e.clientY/window.innerHeight-0.5)+Math.PI*0.5;
      camCtrl.trgtRot.y = 0.5*Math.PI*(e.clientX/window.innerWidth-0.5);
    }
  });

  function updateCameraMovement(camCtrl) {
    if (camCtrl.crntRot.x !== camCtrl.trgtRot.x || camCtrl.crntRot.y !== camCtrl.trgtRot.y) {
      const delta = {
        x: camCtrl.trgtRot.x - camCtrl.crntRot.x,
        y: camCtrl.trgtRot.y - camCtrl.crntRot.y,
      }
      if (Math.abs(delta.x) > 0.001 || Math.abs(delta.y) > 0.001) {
        camCtrl.crntRot.x += 0.1 * delta.x;
        camCtrl.crntRot.y += 0.1 * delta.y;
      } else {
        camCtrl.crntRot.x = camCtrl.trgtRot.x;
        camCtrl.crntRot.y = camCtrl.trgtRot.y;
      }

      orthoCam.position.setFromSphericalCoords(50, camCtrl.crntRot.x, camCtrl.crntRot.y);
      orthoCam.updateProjectionMatrix();
    }
  }

  function updateModelRotation(speed) {
    if (orbit[1]) orbit[1].rotation.z -= speed;
    if (orbitDashed[0]) orbitDashed[0].rotation.z -= speed;
    if (orbitDashed[1]) orbitDashed[1].rotation.z -= speed;
    if (orbitDashed[2]) orbitDashed[2].rotation.z -= speed;
    if (points[0]) points.forEach(point => {point.position.applyAxisAngle(new THREE.Vector3(0,0,-1), speed)});
  }

  function animate() {
    requestAnimationFrame(animate);

    if (orbit[0]) orbit[0].rotation.z -= 0.015; // rotazione sfumatura blu

    if (!TWEEN.update() && !pauseAnimation && bAutorotate) {      
      if (!isZoomed) {
        updateCameraMovement(camCtrl);
        updateModelRotation(0.0005);
      }
    }

    controls.update();

    render();
  }

  function render() {
    if (lineMaterial) lineMaterial.resolution.set( window.innerWidth, window.innerHeight );
    if (lineDashedMaterial) lineDashedMaterial.resolution.set( window.innerWidth, window.innerHeight );
    renderer.render(scene, orthoCam);
    labelRenderer.render(scene, orthoCam);
  }

  function getZoom() {
    return Math.min(window.innerWidth, window.innerHeight) / 3;
  }

  // Tween

  const tweenCtrl = new TWEEN.Tween(controls.target);
  // const tweenCam = new TWEEN.Tween(orthoCam);
  const tweenCamZoom = new TWEEN.Tween(orthoCam);
  let line = { width: 0.002, to: 0.003 };
  const tweenLine = new TWEEN.Tween(line);

  const oldPos = new THREE.Vector3();
  oldPos.copy(orthoCam.position);

  function zoomIn(e) {
    if (e.button !== 0) {
      return;
    }
    if (e.target.index > 0) {
      e.stopPropagation();

      Vue.prototype.$bus.$emit("cycleIn", e.target);

      const target = new THREE.Vector3();
      const pos = new THREE.Vector3();
      const delta = new THREE.Vector3();
      let thisZoom = maxZoom * getZoom();
      let ctrlEnabled = false;

      line.to = 3;

      const idx = e.target.index - 1;
      target.set(
        points[idx].position.x,
        points[idx].position.y,
        points[idx].position.z
      );
      delta.subVectors(target, controls.target);
      pos.addVectors(orthoCam.position, delta);

      oldPos.set(orthoCam.position.x, orthoCam.position.y, orthoCam.position.z);

      myTween(target, pos, thisZoom, ctrlEnabled, true);

      isZoomed = true;
    }
  }

  function zoomOut(e) {
    e?.stopPropagation();

    Vue.prototype.$bus.$emit("cycleOut");

    const target = new THREE.Vector3();
    const pos = new THREE.Vector3();
    let thisZoom = getZoom();
    let ctrlEnabled = false;//true;
    line.to = 2;

    target.set(0, 0, 0);
    pos.copy(oldPos);

    myTween(target, pos, thisZoom, ctrlEnabled, false);

    isZoomed = false;
  }

  function myTween(target, pos, thisZoom, ctrlEnabled, type) {
    tweenCtrl
      .to(
        {
          x: target.x,
          y: target.y,
          z: target.z,
        },
        800
      )
      .easing(type ? TWEEN.Easing.Cubic.Out : TWEEN.Easing.Cubic.In)
      .onStart(() => {
        controls.enabled = false;
      })
      .start();

    tweenCamZoom
      .to(
        {
          zoom: thisZoom,
        },
        800
      )
      .easing(type ? TWEEN.Easing.Sinusoidal.In : TWEEN.Easing.Linear.None)
      .onUpdate(() => {
        orthoCam.updateProjectionMatrix();
      })
      .start();

    tweenLine
      .to({ width: line.to }, 800)
      .onUpdate((obj) => {
        lineMaterial.linewidth = obj.width;
        lineDashedMaterial.linewidth = obj.width;
      })
      .onComplete(() => {
        controls.enabled = ctrlEnabled;
      })
      .start();
  }

  Vue.prototype.$bus.$on("closeCases", zoomOut);
  Vue.prototype.$bus.$on("zoomOut", zoomOut);
  Vue.prototype.$bus.$on("pauseAnimation", () => {
    pauseAnimation = true;
  });
  Vue.prototype.$bus.$on("resumeAnimation", () => {
    pauseAnimation = false;
  });
}
