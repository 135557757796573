<template>
  <div class="news-container">
    <main class="news">
      <h1 class="sans--h1--shadow">
        {{ $labels.news_name }}
      </h1>
      <Archive
        theme="news"
        :data="{
          archive_type: 'archive',
          layout: '',
          archive: 'news',
        }"
      />
    </main>
    <Footer />
  </div>
</template>

<script>
import Archive from '@/components/blocks/archive.vue';
import Footer from '@/components/ui/footer';

export default {
  name: 'News',
  components: {
    Archive,
    Footer,
  },
  mounted() {
    this.$bus.$emit('removeCasesListener');
  },
};
</script>

<style lang="scss" scoped>
.news {
  align-content: start;
  display: grid;
  padding: var(--spacer-xs);
  padding-top: calc(var(--spacer-xs) + var(--header-height));
  row-gap: var(--row-gap-xl);
  padding-bottom: var(--spacer-xl);

  &-container {
    display: grid;
    align-content: space-between;
    background: var(--white);
    z-index: 2;
    height: calc(var(--vh) * 100);
    overflow-y: auto;
    position: fixed;
    width: 100%;
  }

  h1 {
    text-align: center;
    margin-top: var(--spacer-xs);
  }
}
</style>
