import drop from 'lodash.drop';

import medusa from '@/assets/js/observer';

export default {
  data() {
    return {
      request: null,
      type: null,
      items: [],
      placeholder: 'Loading',
      offset: 0,
      skeletonItems: this.data.infinite_scroll
        ? parseInt(this.data.posts_per_page, 10)
        : this.data.archive_type === 'posts'
          ? this.data.posts.length
          : 12,
    };
  },
  mounted() {
    this.setRequest();

    this.loadItems().then(() => {
      if (this.data.infinite_scroll) {
        this.$nextTick(this.setInfiniteScroll);
      } else {
        this.$root.$children[0].lazyObserve(this.$el);
      }
    });
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
  },
  methods: {
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        if (this.request.type === 'case_study') {
          items.sort((a, b) => a.acf.fields.id - b.acf.fields.id);
        }
        this.items = [...this.items, ...items];
      }
      return items;
    },
    setRequest() {
      let postType = null;
      const categories = {};
      let include = [];
      let slugArray = [];

      this.type = this.data.archive_type;

      if (this.type === 'archive') {
        const { archive, archive_category, tags } = this.data;
        postType = archive === 'post' || archive === 'page' ? `${archive}s` : archive;
        if (archive_category) {
          const taxonomy = archive_category.taxonomy === 'category'
            ? 'categories'
            : archive_category.taxonomy;
          categories[taxonomy] = archive_category.term_id;
        }
        if (tags) {
          categories.tags = tags;
        }
        include = this.data[postType] ? this.data[postType] : [];
      } else if (this.type === 'posts') {
        const currentPostType = this.data[this.type][0].post_type;
        postType = currentPostType !== 'product'
          ? `${currentPostType}s`
          : currentPostType;
        slugArray = currentPostType !== 'product'
          ? this.data[this.type].map((item) => item.post_name)
          : [];
        include = currentPostType === 'product'
          ? this.data[this.type].map((item) => item.ID)
          : [];
      } else if (this.type === 'taxonomy') {
        postType = 'categories';
        include = this.data[postType].map((item) => item.term_id);
      }

      const slug = slugArray.length > 0
        ? this.data.infinite_scroll
          ? drop(slugArray, this.offset)
          : slugArray
        : null;
      const offset = this.type !== 'posts' ? this.offset : 0;

      this.request = {
        type: postType,
        params: {
          ...categories,
          slug,
          include: include.length > 0 ? include : null,
          per_page: this.data.infinite_scroll
            ? parseInt(this.data.posts_per_page, 10) || 12
            : parseInt(this.data.max_posts, 10) || 100,
          offset,
          orderby:
            slugArray.length > 0
              ? 'include_slugs'
              : include.length > 0
                ? 'include'
                : this.type === 'taxonomy'
                  ? 'id'
                  : 'menu_order',
          lang: this.$store.state.lang,
        },
      };
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: 'infinite-scroll',
        threshold: 0.0,
        nodes: [],
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
      });

      medusa.ref.pushToTarget(
        'infinite-scroll',
        this.$el.querySelector('.infinite'),
      );
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      if (
        this.type === 'posts'
        && this.offset + (parseInt(this.data.posts_per_page, 10) || 12)
          >= this.data[this.type].length
      ) {
        medusa.ref.removeTarget('infinite-scroll');
        return;
      }
      this.offset += parseInt(this.data.posts_per_page, 10) || 12;
      this.setRequest();
      this.loadItems().then((newItems) => {
        this.$root.$children[0].lazyObserve(this.$el);

        if (newItems.length === 0) {
          medusa.ref.removeTarget('infinite-scroll');
        } else if (
          window.pageYOffset + window.innerHeight
          === document.body.offsetHeight
        ) {
          this.requestOffset();
        }
      });
    },
  },
};
