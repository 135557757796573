<template>
  <footer>
    <div class="footer-sections">
      <div
        v-for="(column, index) in footer.columns"
        :key="index"
      >
        <div
          v-for="(section, index) in column.column.sections"
          :key="index"
        >
          <h6>
            {{ section.section.title }}
          </h6>
          <h4>{{ section.section.text }}</h4>
        </div>
      </div>
    </div>
    <Figure
      class="footer-logo"
      :data="{ ...footer.logo }"
    />
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import Figure from '@/components/media/figure';

export default {
  name: 'Footer',
  components: {
    Figure,
  },
  computed: {
    ...mapGetters(['footer']),
  },
};
</script>

<style lang="scss">
footer {
  display: grid;
  background: var(--grey);
  justify-items: start;
  padding-top: var(--spacer-xs);

  @include mq(s) {
    padding-top: 0;
  }

  .footer-sections {
    padding-top: var(--spacer-xl);
    padding-bottom: var(--spacer-s);
    row-gap: var(--row-gap-s);
    grid-auto-columns: minmax(0, 1fr);
    display: grid;

    @include mq(s) {
      padding-top: var(--spacer-m);
      padding-bottom: var(--spacer-m);
      grid-auto-flow: column;
    }

    > * {
      display: grid;
      row-gap: var(--row-gap-s);
      align-content: start;
      padding-left: calc(var(--spacer-xs) + var(--column-gap-xs));
      padding-right: calc(var(--spacer-xs) + var(--column-gap-xs));

      > * {
        display: grid;
        row-gap: var(--row-gap-xxs);

        h4 {
          white-space: pre-line;
        }
      }
    }
  }

  h6 {
    text-transform: uppercase;
  }

  .footer-logo {
    display: grid;
    padding: calc(var(--spacer-xs) + var(--column-gap-xs));
    grid-row: 1;

    @include mq(s) {
      grid-row: initial;
    }

    img {
      width: 160px;
    }
  }
}
</style>
