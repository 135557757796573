var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main--list"},[_c('h1',{staticClass:"sans--h1--shadow"},[_vm._v(" "+_vm._s(_vm.$labels.archive_name)+" ")]),_c('div',{staticClass:"main--list__filters"},[_c('div',{staticClass:"triangled",on:{"click":function($event){return _vm.toggleFilters()}}},[_c('h5',{staticClass:"sans--h5--alt trim--sans"},[_vm._v(" "+_vm._s(_vm.$labels.filters)+" ")]),_c('div',{class:['triangle', _vm.filtersAreVisible ? 'triangle--reverse' : false]})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filtersAreVisible),expression:"filtersAreVisible"}]},_vm._l((_vm.phases),function(phase){return _c('Cta',{key:phase.id,attrs:{"theme":_vm.activePhase === phase.id ? 'filter--active' : 'filter',"data":{
          fn: function () { return _vm.filterItems(phase); },
          title: phase.name,
        }}})}),1)]),_c('Archive',{attrs:{"theme":"list","active-phase":_vm.activePhase,"data":{
      archive_type: 'archive',
      layout: '',
      archive: 'case_study',
      thumb: 'list',
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }