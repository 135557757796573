<template>
  <div
    ref="container"
    tabindex="-1"
    class="cases__container"
    @keydown.esc="close"
  >
    <div class="cases">
      <div class="cases__header">
        <Cta
          class="cases__header__cta"
          theme="nav--inverse"
          :data="{
            fn: close,
            title: $labels.close_cta,
          }"
        />
        <Cta
          v-visible="false"
          theme="nav--inverse"
          :data="{
            fn: close,
            title: $labels.close_cta,
          }"
        />
        <h1 class="sans--h1--shadow trim--sans">
          {{ phase.name }}
        </h1>
        <Cta
          v-visible="false"
          theme="nav--inverse"
          :data="{
            fn: close,
            title: $labels.close_cta,
          }"
        />
      </div>
      <div class="cases__header__body">
        <div
          v-if="phase.description && phase.description !== ''"
          class="cases__header__description trim--sans sans--h4"
          v-html="phase.description"
        />
        <Archive
          v-show="phase"
          :active-phase="phase.id"
          :data="{
            archive_type: 'archive',
            layout: '',
            archive: 'case_study',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import Archive from '@/components/blocks/archive.vue';
import Cta from '@/components/typo/cta';

export default {
  name: 'Cases',
  components: {
    Archive,
    Cta,
  },
  props: {
    phase: {
      type: [Object, Boolean],
      default: false,
    },
    sub: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  mounted() {
    this.addListener();
    this.$refs.container.focus();
    this.$bus.$on('addCasesListener', this.addListener);
    this.$bus.$on('removeCasesListener', this.removeListener);
  },
  methods: {
    ...mapMutations(['TOGGLE_CASES']),
    addListener() {
      window.addEventListener('pointerdown', this.handleClick);
    },
    removeListener() {
      window.removeEventListener('pointerdown', this.handleClick);
    },
    handleClick(event) {
      if (!this.$refs.container) {
        return;
      }
      if (!this.$refs.container.contains(event.target)) {
        this.close(event);
      }
    },
    close(e) {
      this.$emit('close');
      this.TOGGLE_CASES(false);
      this.removeListener();

      if (!this.sub) {
        this.$bus.$emit('closeCases', e);
      }
    },
  },
};
</script>

<style lang="scss">
  .cases {
    &__container {
      display: grid;
      justify-content: center;
      position: fixed;
      height: calc(var(--vh) * 100);
      padding: var(--spacer-xs);
      pointer-events: none;
      z-index: 3;
      width: 100%;
    }

    background: var(--blue);
    border-radius: var(--border-radius);
    display: grid;
    grid-template-columns: minmax(500px, min-content);
    grid-template-rows: auto;
    grid-auto-rows: 1fr;
    padding: var(--spacer-xs);
    pointer-events: all;
    height: 100%;
    overflow: auto;
    row-gap: var(--row-gap-m);

    &__header {
      column-gap: var(--column-gap-m);
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      width: 100%;

      &__body {
        display: grid;
        row-gap: var(--row-gap-xl);
        align-content: start;
      }

      &__description {
        box-shadow: calc(-1 * var(--line)) calc(-1 * var(--line)) 0px
          calc(var(--line) * 2) var(--shadow);
        border-radius: var(--border-radius);
        padding: var(--spacer-s);
        a {
          text-decoration: underline;
        }
      }

      &__cta {
        position: fixed;
        z-index: 1;
      }

      h1 {
        text-align: center;
      }
    }
  }
</style>
