export default {
  SET_LANG(state, value) {
    state.lang = value;
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  TOGGLE_VIEW(state, value) {
    state.listView = value;
  },
  TOGGLE_CASES(state, value) {
    state.cases = value;
  },
  TOGGLE_MENU(state, value) {
    state.mobileMenu = value;
  },
};
