<template>
  <div
    class="gallery__container"
    @mouseout="resetCursor"
  >
    <div
      ref="gallery"
      class="gallery"
    >
      <div
        v-for="(item, index) in data"
        :key="index"
        class="item"
        :data-item-id="item.id"
      >
        <Figure
          v-if="item.type === 'image'"
          :ref="index"
          :data="{ ...item, ratio: true }"
        />
        <video
          v-if="item.type === 'video'"
          :src="item.url"
          autoplay
          muted
          loop
          playsinline
        />
      </div>
    </div>
    <button
      v-if="data.length > 1"
      class="left"
      @mouseenter="setCursor"
      @mousemove="setCursor"
      @click="previous"
    />
    <button
      v-if="data.length > 1"
      class="right"
      @mouseenter="setCursor"
      @mousemove="setCursor"
      @click="next"
    />
    <img
      id="cursor"
      :style="{ display: cursor.display, top: cursor.top, left: cursor.left }"
      :src="require(`@/assets/svg/icons/arrow-${cursor.arrow}.svg`)"
    >
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';

require('flickity-imagesloaded');

export default {
  name: 'Gallery',
  components: {
    Figure,
  },
  props: {
    data: {
      type: [Boolean, Array],
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        contain: 'true',
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
      },
      cursor: {
        display: 'none',
        top: 0,
        left: 0,
        arrow: 'left',
      },
    };
  },
  mounted() {
    this.gallery = new Flickity(
      this.$refs.gallery,
      Object.assign(this.settings, this.extraSettings),
    );
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
  methods: {
    next() {
      this.gallery.next(false, true);
    },
    previous() {
      this.gallery.previous(false, true);
    },
    setCursor(event) {
      this.cursor.display = 'initial';
      this.cursor.top = `${event.pageY}px`;
      this.cursor.left = `${event.pageX}px`;
      this.cursor.arrow = event.target.className;
    },
    resetCursor() {
      this.cursor.display = 'none';
    },
  },
};
</script>

<style lang="scss">
  #cursor {
    --cursor-size: 22px;

    position: fixed;
    transform: translate(-50%, -50%);
    mix-blend-mode: difference;
    height: var(--cursor-size);
    width: var(--cursor-size);
    pointer-events: none;

    @media (pointer: coarse) {
      display: none !important;
    }
  }
  .gallery {
    &__container {
      position: relative;
      height: 0;
      padding-bottom: calc(9 / 16 * 100%);

      button {
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;

        @media (hover: hover) {
          &:hover {
            cursor: none;
          }
        }

        &.right {
          right: 0;
        }
      }
    }

    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--black);

    .flickity {
      &-viewport {
        overflow: visible;
      }
    }

    .item {
      width: 100%;
      display: grid;
      height: 100%;

      figure {
        position: relative;
        height: 0;
        padding-bottom: calc(9 / 16 * 100%);
        width: 100%;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: contain;
      }

      video {
        width: 100%;
      }
    }
  }
</style>
