import axios from 'axios';
import { relativeUrl } from '@/assets/js/utils';

// eslint-disable-next-line no-undef
let { url } = __VUE_WORDPRESS__.routing;
url = url.replace(':8888', ':3000');

const urlWp = `${url}/wp-json/`;

const ajax = axios.create(
  {
    baseURL: urlWp,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
);

ajax.interceptors.response.use(
  // eslint-disable-next-line no-undef
  (response) => relativeUrl(response, __VUE_WORDPRESS__.routing.url),
  (error) => Promise.reject(error),
);

export default ajax;
