<template>
  <div class="not-found">
    <div class="not-found__image">
      <inline-svg :src="require(`@/assets/svg/404.svg`)" />
      <inline-svg :src="require(`@/assets/svg/404.svg`)" />
    </div>
    <div class="not-found__text">
      <h5>{{ $labels.not_found_message }}</h5>
      <Cta
        theme="nav--large"
        :data="{ title: $labels.not_found_cta, url: { name: 'Home' } }"
      />
    </div>
  </div>
</template>

<script>
import Cta from '@/components/typo/cta.vue';

export default {
  name: 'NotFound',
  components: { Cta },
};
</script>

<style lang="scss">
  .not-found {
    position: absolute;
    bottom: 0;
    background: var(--grey);
    display: grid;
    grid-template-rows: minmax(0, 1fr) auto auto;
    height: calc(var(--vh) * 100 - var(--header-height));
    row-gap: var(--row-gap-m);
    padding: var(--spacer-xs);
    padding-top: var(--header-height);
    z-index: 2;
    width: 100%;

    &__image {
      display: grid;
      --shadow-size: 1px;

      @include mq(s) {
        --shadow-size: 3px;
      }

      > * {
        grid-area: 1 / 1;
      }

      svg {
        fill: var(--grey);
        width: 100%;
        height: 100%;
      }

      svg:first-child {
        filter: drop-shadow(
          calc(-1 * var(--shadow-size)) calc(-1 * var(--shadow-size))
            var(--white)
        );
      }

      svg:last-child {
        filter: drop-shadow(
          var(--shadow-size) var(--shadow-size) 0 var(--black)
        );
      }
    }

    &__text {
      display: grid;
      justify-items: center;
      row-gap: var(--row-gap-xs);
    }
  }
</style>
