<template>
  <div class="page__container">
    <main class="page">
      <div class="page__header page__header--about">
        <h1 class="trim--sans">
          {{ about.header.main }}
        </h1>
        <div
          class="page__header__sub serif--p1"
          v-html="about.header.sub"
        />
      </div>
      <div class="page__body page__body--about">
        <div
          v-for="(section, index) in about.body.sections"
          :key="index"
          class="page__body__section"
        >
          <h1
            class="page__body__section__title page__body__section__title--about trim--sans"
          >
            {{ section.section.title }}
          </h1>
          <div
            class="page__body__section__text page__body__section__text--about"
            v-html="section.section.text"
          />
        </div>
        <div class="page__body__gaps page__body__gaps--about" />
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import data from '@/mixins/data';
import Footer from '@/components/ui/footer';

export default {
  name: 'About',
  components: {
    Footer,
  },
  mixins: [data],
  computed: {
    about() {
      return this.post.acf.about;
    },
  },
};
</script>
