var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"case__container",attrs:{"tabindex":"-1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}}},[_c('div',{ref:"case",staticClass:"case"},[_c('Cta',{staticClass:"close",attrs:{"theme":"nav--large","data":{
        fn: _vm.close,
        title: _vm.$labels.close_cta,
      }}}),_c('div',{staticClass:"case__header"},[_c('h1',{staticClass:"serif--h1 trim--serif--display"},[_vm._v(" "+_vm._s(_vm.post.title.rendered)+" ")]),_c('Gallery',{attrs:{"data":_vm.post.acf.gallery}})],1),_c('div',{staticClass:"case__details"},[_vm._l((_vm.orderedFields),function(field,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(field.values),expression:"field.values"}],key:index,staticClass:"case__details__detail"},[_c('div',{class:[
            'case__details__detail__label',
            field.explanation
              ? 'case__details__detail__label--hoverable'
              : false ]},[_c('div',[_c('h5',{directives:[{name:"touch",rawName:"v-touch:tap",value:(
                function () {
                  field.explanation ? _vm.toggleExplanation() : false;
                }
              ),expression:"\n                () => {\n                  field.explanation ? toggleExplanation() : false;\n                }\n              ",arg:"tap"}],staticClass:"sans--h5--alt trim--sans",on:{"mouseenter":function($event){field.explanation ? _vm.toggleExplanation() : false},"mouseleave":function($event){field.explanation ? _vm.toggleExplanation() : false}}},[_vm._v(" "+_vm._s(field.label)+" "),(field.explanation)?_c('sup',[_vm._v("?")]):_vm._e()]),(field.explanation)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.explanationIsVisible),expression:"explanationIsVisible"}],staticClass:"case__details__detail__explanation"},[_c('div',{staticClass:"case__details__detail__explanation__triangle"}),_c('h5',{staticClass:"trim--sans"},[_vm._v(" "+_vm._s(field.explanation)+" ")])]):_vm._e()])]),(field.label !== 'Url')?_c('h4',{staticClass:"trim--sans"},_vm._l((field.values),function(value,valueIndex){return _c('span',{key:value.term_taxonomy_id},[_vm._v(" "+_vm._s(("" + (value.name) + (valueIndex + 1 !== field.values.length ? ", " : "")))+" ")])}),0):(field.values)?_c('Cta',{attrs:{"theme":"small","data":{
            url: field.values.url,
            target: '__blank',
            title: field.values.title,
          }}}):_vm._e()],1)}),_c('div',{staticClass:"case__details__detail end"})],2),_c('div',{staticClass:"case__text",domProps:{"innerHTML":_vm._s(_vm.post.acf.text)}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }