<template>
  <router-link
    :to="data.link"
    class="thumb--list"
    @click.native.stop
  >
    <h5 class="sans--h5--alt">
      {{ $labels.id }}
    </h5>
    <h4>
      {{ `00${data.acf.fields.id}`.slice(-3) }}
    </h4>
    <h5 class="sans--h5--alt">
      {{ $labels.title }}
    </h5>
    <div class="trim--serif--display">
      <h3 class="serif--h3">
        {{ data.title.rendered }}
      </h3>
    </div>
    <h5 class="sans--h5--alt">
      {{ $labels.phases }}
    </h5>
    <h4>
      <span
        v-for="(phase, phaseIndex) in data.gds_taxonomies.case_study_phase"
        :key="phase.term_taxonomy_id"
      >{{
        `${phase.name}${
          phaseIndex + 1 !== data.gds_taxonomies.case_study_phase.length
            ? ", "
            : ""
        }`
      }}</span>
    </h4>
    <h5 class="sans--h5--alt">
      {{ $labels.category }}
    </h5>
    <h4>
      <span
        v-for="(category, categoryIndex) in data.gds_taxonomies
          .case_study_category"
        :key="category.term_taxonomy_id"
      >{{
        `${category.name}${
          categoryIndex + 1 !== data.gds_taxonomies.case_study_category.length
            ? ", "
            : ""
        }`
      }}</span>
    </h4>
    <h5 class="sans--h5--alt">
      {{ $labels.year }}
    </h5>
    <h4>
      <span
        v-for="(year, yearIndex) in data.gds_taxonomies.case_study_year"
        :key="year.term_taxonomy_id"
      >{{
        `${year.name}${
          yearIndex + 1 !== data.gds_taxonomies.case_study_year.length
            ? ", "
            : ""
        }`
      }}</span>
    </h4>
  </router-link>
</template>

<script>
export default {
  name: 'Thumb',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .thumb {
    &--list {
      border-bottom: var(--line) solid var(--grey-d);
      column-gap: var(--column-gap-m);
      row-gap: var(--row-gap-s);
      display: grid;
      grid-template-columns: minmax(0, 72px) minmax(0, 1fr);
      padding: var(--spacer-m) 0;

      @include mq(s) {
        grid-template-columns:
          minmax(0, 40px) minmax(300px, 1fr) minmax(0, 500px) minmax(0, 300px)
          minmax(0, 60px);
      }

      @include mq(m) {
        grid-template-columns:
          minmax(0, 40px) minmax(500px, 1fr) minmax(0, 500px)
          minmax(0, 300px) minmax(0, 60px);
      }

      h2 {
        place-self: center;
        max-width: 240px;
        text-align: center;
      }

      h3,
      h5 {
        @include mq(s) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .sans--h5--alt {
        display: initial;

        @include mq(s) {
          display: none;
        }
      }
    }
  }
</style>
