var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"menu"},[_c('div',{staticClass:"home"},[_c('Cta',{attrs:{"theme":_vm.theme === 'blue' ? 'nav--blue' : 'nav',"data":_vm.listView && _vm.$route.name === 'Cases' && !_vm.$mq.isMobileS
          ? { fn: function () { return _vm.TOGGLE_VIEW(false); }, title: _vm.headerMenu[0].content }
          : {
            url: _vm.listView ? { name: 'Cases' } : { name: 'Home' },
            title: _vm.headerMenu[0].content,
          }}})],1),(!_vm.$mq.isMobileS)?_c('div',{staticClass:"pages"},_vm._l((_vm.headerMenu.slice(1)),function(item){return _c('Cta',{key:item.url,attrs:{"theme":_vm.theme === 'blue' ? 'nav--blue' : 'nav',"data":{
        url: item.url,
        title: item.content,
      }}})}),1):_c('Cta',{attrs:{"theme":_vm.theme === 'blue' ? 'nav--blue' : 'nav',"data":{
      fn: function () { return _vm.TOGGLE_MENU(!_vm.mobileMenu); },
      title: _vm.mobileMenu ? _vm.$labels.close_cta : _vm.$labels.menu_cta,
    }}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobileMenu),expression:"mobileMenu"}],staticClass:"mobile-menu"},_vm._l((_vm.headerMenu.slice(1)),function(item){return _c('Cta',{key:item.url,attrs:{"theme":"menu","data":{
        url: item.url,
        title: item.content,
      }}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }