<template>
  <router-link
    :to="data.link"
    class="thumb--news"
  >
    <Figure
      v-if="data.gds_featured_image"
      class=""
      :data="{ ...data.gds_featured_image, ratio: 2 / 3 }"
    />
    <div
      v-else
      :style="{
        paddingBottom: `${(2 / 3) * 100}%`,
        background: 'var(--grey)',
      }"
    />
    <aside>
      <div>
        <h3>
          <span class="sans--h5--alt">
            {{ date }}
          </span>
          {{ data.title.rendered }}
        </h3>
      </div>
      <div
        class="excerpt"
        v-html="data.content.rendered"
      />
    </aside>
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';

export default {
  name: 'NewsThumb',
  components: { Figure },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date() {
      const date = new Date(this.data.date);
      return date.toLocaleDateString('it').replaceAll('/', '.');
    },
  },
};
</script>

<style lang="scss">
.thumb {
  &--news {
    border-radius: 2px;
    gap: var(--column-gap-s);
    display: grid;
    width: 100%;

    @media (hover: hover) {
      &:hover {
        background: var(--blue);
        color: var(--white);
        box-shadow: 0 0 0 calc(var(--spacer-xs) / 2) var(--blue);

        h3,
        span {
          color: var(--white);
        }
      }
    }

    @include mq(m) {
      grid-template-columns: 1fr 2fr;
    }

    aside {
      align-self: start;
      display: grid;
      gap: var(--row-gap-s);

      .excerpt {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        > *:not(p:first-child) {
          display: none;
        }
      }
    }
  }
}
</style>
