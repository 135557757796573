<template>
  <router-link
    :to="data.link"
    class="thumb"
    @click.native.stop
  >
    <h5 class="sans--h5--shadow trim--sans">
      {{ `00${data.acf.fields.id}`.slice(-3) }}
    </h5>
    <h2 class="serif--h2 trim--serif--display">
      <span>
        {{ data.title.rendered }}
      </span>
    </h2>
  </router-link>
</template>

<script>
export default {
  name: 'Thumb',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .thumb {
    background: var(--blue);
    border-radius: var(--border-radius);
    box-shadow: var(--line) var(--line) 0px calc(var(--line) * 2) var(--shadow);
    display: grid;
    min-height: 140px;
    padding: var(--spacer-xs);

    @media (hover: hover) {
      &:hover {
        box-shadow: 0px 0px 0px var(--line) var(--shadow);
        transform: translate(var(--line), var(--line));
      }
    }

    > * {
      grid-area: 1 / 1;
    }

    h2 {
      padding: var(--spacer-xs) 0;
      max-width: 90%;
      place-self: center;
      text-align: center;

      span {
        display: flex;
        justify-content: center;
      }
    }
  }
</style>
