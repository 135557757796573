<template>
  <transition
    name="cookie"
    appear
  >
    <div
      v-show="!cookie && init"
      class="cookie sans--h5"
    >
      <Richtext
        v-if="options.cookies.text"
        :data="{
          value: options.cookies.text,
        }"
      />
      <Cta
        theme="nav"
        :data="{
          title: $labels.close_cta,
          fn: acceptCookie,
        }"
      />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';

import { loadScript } from '@/assets/js/utils';

import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

// eslint-disable-next-line no-undef
const id = __VUE_WORDPRESS__.state.options?.cookies?.id;

export default {
  name: 'Cookie',
  components: {
    Richtext,
    Cta,
  },
  data() {
    return {
      init: true,
      cookie: !!Cookies.get(id),
    };
  },
  computed: {
    ...mapGetters(['transition', 'options']),
  },
  watch: {
    // transition(val) {
    //   if (!val) this.init = true;
    // },
  },
  mounted() {
    // window.addEventListener('click', this.acceptCookie);
    window.addEventListener('scroll', this.acceptCookie);
  },
  methods: {
    acceptCookie() {
      Cookies.set(this.options.cookies.id, '1', { expires: 365 });
      this.cookie = true;

      if (this.options.scripts && this.options.scripts.scripts_repeater) {
        this.options.scripts.scripts_repeater.forEach((item) => {
          if (!item.init) {
            if (item.inline) {
              const s = document.createElement('span');
              s.style.display = 'none';
              s.innerHTML = item.script;
              document.getElementsByTagName('body')[0].appendChild(s);
            } else {
              loadScript(item.script_src);
            }
          }
        });
      }

      if (this.$gtag) {
        this.$gtag.config({
          params: {
            anonymize_ip: false,
            send_page_view: false,
          },
        });
      }

      window.removeEventListener('click', this.acceptCookie);
      window.removeEventListener('scroll', this.acceptCookie);
    },
  },
};
</script>

<style lang="scss" scoped>
  .cookie-enter-active,
  .cookie-leave-active {
    transition: all var(--timing) ease;
  }
  .cookie-enter,
  .cookie-leave-to {
    transform: translateY(100%);
  }
  .cookie {
    background: var(--grey);
    border-radius: var(--border-radius);
    bottom: 0;
    box-shadow: var(--line) var(--line) 0px calc(var(--line) * 2) var(--grey-d);
    display: grid;
    width: calc(100% - var(--spacer-xs) * 2);
    padding: var(--spacer-xs);
    position: fixed;
    margin: var(--spacer-xs);
    right: 0;
    row-gap: var(--row-gap-xs);
    z-index: 3;

    @include mq(s) {
      max-width: 600px;
    }

    .cta {
      justify-self: center;

      @include mq(s) {
        justify-self: end;
      }
    }
  }
</style>
