<template>
  <div class="view-toggle sans--h5">
    <div :class="listView ? false : 'view-toggle__text--active'">
      Cycle
    </div>
    <div
      :class="[
        'view-toggle__toggle',
        listView ? 'view-toggle__toggle--checked' : false,
      ]"
    >
      <input
        id="view"
        v-model="model"
        type="checkbox"
      >
      <div class="view-toggle__toggle__shape" />
    </div>
    <div :class="listView ? 'view-toggle__text--active' : false">
      List
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ViewToggle',
  computed: {
    ...mapGetters(['listView']),
    model: {
      get() {
        return this.listView;
      },
      set(value) {
        this.TOGGLE_VIEW(value);
      },
    },
  },
  methods: {
    ...mapMutations(['TOGGLE_VIEW']),
  },
};
</script>

<style lang="scss">
  .view-toggle {
    --view-toggle-height: 24px;
    --view-toggle-width: 60px;

    align-items: center;
    bottom: var(--spacer-xs);
    column-gap: var(--column-gap-xs);
    display: none;
    grid-auto-flow: column;
    position: fixed;
    right: var(--spacer-xs);
    text-transform: uppercase;
    z-index: 1;

    @include mq(s) {
      display: grid;
    }

    &__text {
      &--active {
        color: var(--blue);
      }
    }

    &__toggle {
      background: var(--grey);
      border-radius: calc(var(--view-toggle-height) / 2);
      display: grid;
      height: var(--view-toggle-height);
      position: relative;
      transition: background-color 0.4s ease;
      width: var(--view-toggle-width);

      input {
        height: 100%;
      }

      &__shape {
        --view-toggle-shape-margin: 3px;
        --view-toggle-shape-size: calc(
          var(--view-toggle-height) - var(--view-toggle-shape-margin) * 2
        );

        background: var(--blue);
        border-radius: calc(var(--view-toggle-shape-size) / 2);
        height: var(--view-toggle-shape-size);
        left: 0;
        margin: var(--view-toggle-shape-margin);
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: all 0.4s ease;
        width: var(--view-toggle-shape-size);
      }

      &--checked {
        background: var(--white);

        .view-toggle__toggle__shape {
          left: calc(
            100% - var(--view-toggle-shape-size) -
              var(--view-toggle-shape-margin) * 2
          );
        }
      }
    }
  }
</style>
